<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filterSearch.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      :clearable="false"
                      placeholder="Hệ đào tạo"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filterSearch.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      placeholder="Khóa học"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    label-for="coursesSemestersSelected"
                  >
                    <v-select
                      v-model="filterSearch.courseSemestersId"
                      placeholder="Học kỳ:"
                      :reduce="option => option.value"
                      :options="coursesSemesters"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  md="3"
                >
                  <b-form-group
                    label-for="minCGPA"
                  >
                    <b-form-input
                      v-model="filterSearch.minCpa"
                      placeholder="Lọc theo điểm tổng kết"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <div class="text-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.modal
                      size="xl"
                      class="ml-1"
                      variant="primary"
                      @click="btnSearchClick"
                    >
                      <feather-icon icon="SearchIcon" /> Tìm kiếm
                    </b-button>
                    <b-button
                      v-if="creatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="ml-1"
                      variant="success"
                      @click="$bvModal.show('bv-modal')"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="PlusIcon" /> Thêm nhận học bổng
                      </span>
                    </b-button>
                    <b-button
                      variant="secondary"
                      class="ml-1"
                      @click="downloadFile"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Xuất excel
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-col cols="12">
              <div class="text-right" />
            </b-col>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="studentScholarships"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'scholarshipFundId'">
                      {{ getScholarShipFundsName(props.row.scholarshipFundId) }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
<!--                      <b-button-->
<!--                        v-if="updatable"-->
<!--                        v-b-modal.studentScholarshipSaveModal-->
<!--                        variant="primary"-->
<!--                        class="btn-icon"-->
<!--                        size="sm"-->
<!--                        @click="onEditStudentScholarship(props.row)"-->
<!--                      >-->
<!--                        <feather-icon icon="Edit2Icon" />-->
<!--                      </b-button>-->
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDeleteStudentScholarship(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <b-modal
      id="bv-modal"
      hide-footer
      size="xl"
    >
      <template #modal-title>
        Lọc danh sách sinh viên đạt học bổng
      </template>
      <div class="d-block text-center">
        <formFilterStudentScholarship @addSuccess="addSuccess" />
      </div>
    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
  BForm, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import formFilterStudentScholarship from '@/views/student-scholarship/formFilterStudentScholarship.vue'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'StudentScholarship',
  directives: {
    Ripple,
  },
  components: {
    formFilterStudentScholarship,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        organizationId: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 10,
        code: null,
        name: null,
        scholarshipId: null,
        student: null,
        status: null,
        sort: null,
      },
      currentStudentScholarship: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'STT',
          field: 'stt',
          width: '80px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên sinh viên',
          field: 'student',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          width: '20%',
          thClass: 'text-center',
        },
        {
          label: 'Mã sinh viên',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lớp cố định',
          field: 'className',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khóa học',
          field: 'nameCourse',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'nameSemester',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'GPA',
          field: 'gpa',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Loại học bổng',
          field: 'scholarship',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Quỹ học bổng',
          field: 'scholarshipFundId',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '40px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      columnFilters: {},
      filterSearch: {
        trainingSystemId: null,
        courseId: null,
        courseSemestersId: null,
      },
      sort: [
        {
          field: '',
          type: '',
        },
      ],
      page: 1,
      perPage: 10,
      user: getUser(),
    }
  },
  computed: {
    ...mapGetters({
      studentScholarships: 'studentScholarship/studentScholarships',
      totalRows: 'studentScholarship/totalRows',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      coursesSemesters: 'dropdown/courseSemesters',
      scholarshipFunds: 'studentScholarship/scholarshipFunds',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.STUDENTSCHOLARSHIP)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.STUDENTSCHOLARSHIP)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.STUDENTSCHOLARSHIP)
    },
  },

  watch: {
    // listening change data init
    'filterSearch.trainingSystemId': function (newVal) {
      this.onChangeTrainingSystem(newVal)
    },
    'filterSearch.courseId': function (newVal) {
      this.onChangeCourse(newVal)
    },

  },

  async created() {
    this.isLoading = true
    try {
      await this.getInitData()
      if (this.trainingSystems.length > 0) {
        this.filterSearch.trainingSystemId = this.trainingSystems[0].value
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      begin: 'studentScholarship/begin',
      getStudentScholarships: 'studentScholarship/getStudentScholarships',
      deleteStudentScholarship: 'studentScholarship/deleteStudentScholarship',
      getTrainingSystem: 'dropdown/getTrainingSystems',
      getCourse: 'dropdown/getCourses',
      getCoursesSemesters: 'dropdown/getCourseSemesters',
      getScholarshipFunds: 'studentScholarship/getScholarshipFunds',
    }),

    onEditStudentScholarship(studentScholarship) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = studentScholarship
      this.currentStudentScholarship = rest
    },
    onDeleteStudentScholarship(studentScholarship) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa nhận học bổng <span class="text-danger">${studentScholarship.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteStudentScholarship(studentScholarship.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getStudentScholarships(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getStudentScholarshipsFromStore()
    },
    async getStudentScholarshipsFromStore() {
      this.isLoading = true
      try {
        let params = {
          courseSemestersId: this.filterSearch.courseSemestersId,
          minCpa: this.filterSearch.minCpa,
        }
        params = { ...this.filter, ...params }
        await this.getStudentScholarships(params)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getStudentScholarshipsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getStudentScholarshipsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'scholarship')) this.updateParams({ scholarship: columnFilters.scholarship })
      if (Object.hasOwn(columnFilters, 'student')) this.updateParams({ student: columnFilters.student })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getStudentScholarshipsFromStore()
    },

    async onSucceed() {
      await this.getStudentScholarshipsFromStore()
    },

    async getInitData() {
      await Promise.all([
        this.getTrainingSystem({ organizationId: this.user.orgId }),
        this.getScholarshipFunds({
          organizationId: null,
          currentPage: 1,
          itemsPerPage: 100000,
          name: '',
          status: '',
          sort: '',
        }),
      ])
    },

    async onChangeTrainingSystem(id) {
      await this.getCourse({
        organizationId: getUser().orgId,
        trainingSystemId: id,
      })
      if (this.courses.length > 0) {
        this.filterSearch.courseId = this.courses[0].value
      }
    },

    async onChangeCourse(id) {
      await this.getCoursesSemesters({
        organizationId: getUser().orgId,
        trainingSystemId: this.filterSearch.trainingSystemId,
        courseId: id,
      })
      if (this.coursesSemesters.length > 0) {
        this.filterSearch.courseSemestersId = this.coursesSemesters[0].value
      }
    },

    async btnSearchClick() {
      await this.getStudentScholarshipsFromStore()
    },

    async showModal() {
      this.$bvModal.show('bv-modal')
    },

    async addSuccess() {
      this.$bvModal.hide('bv-modal')
      await this.getStudentScholarshipsFromStore()
    },

    downloadFile() {
      const columns = [
        { index: 1, width: 40 },
        { index: 2, width: 150 },
        { index: 3, width: 100 },
        { index: 4, width: 200 },
        { index: 5, width: 200 },
        { index: 6, width: 80 },
        { index: 7, width: 200 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'STT',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#00a3d4',
            },
          },
          {
            index: 2,
            value: 'HO_TEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#00a3d4',
            },
          },
          {
            index: 3,
            value: 'MA_SV',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#00a3d4',
            },
          },
          {
            index: 4,
            value: 'KHOA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#00a3d4',
            },
          },
          {
            index: 5,
            value: 'HOC_KY',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#00a3d4',
            },
          },
          {
            index: 6,
            value: 'DIEM_CGPA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#00a3d4',
            },
          },
          {
            index: 7,
            value: 'LOAI_HOC_BONG',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#00a3d4',
            },
          },
        ],
      }
      const dataRows = []
      let index = 2
      let indexCell = 1
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.studentScholarships.length; i++) {
        const cells = []

        const cellItem1 = {
          index: indexCell,
          value: index - 1,
          style: { hAlign: 'Center' },
        }
        // eslint-disable-next-line no-plusplus
        indexCell++
        const cellItem2 = {
          index: indexCell,
          value: this.studentScholarships[i].student,
        }
        // eslint-disable-next-line no-plusplus
        indexCell++
        const cellItem3 = {
          index: indexCell,
          value: this.studentScholarships[i].code,
          style: { hAlign: 'Center' },
        }
        // eslint-disable-next-line no-plusplus
        indexCell++
        const cellItem4 = {
          index: indexCell,
          value: this.studentScholarships[i].nameCourse,
        }
        // eslint-disable-next-line no-plusplus
        indexCell++
        const cellItem5 = {
          index: indexCell,
          value: this.studentScholarships[i].nameSemester,
        }
        // eslint-disable-next-line no-plusplus
        indexCell++
        const cellItem6 = {
          index: indexCell,
          value: this.studentScholarships[i].gpa,
          style: { hAlign: 'Center' },
        }
        // eslint-disable-next-line no-plusplus
        indexCell++
        const cellItem7 = {
          index: indexCell,
          value: this.studentScholarships[i].scholarship,
        }
        cells.push(cellItem1, cellItem2, cellItem3, cellItem4, cellItem5, cellItem6, cellItem7)
        const cell = {
          index,
          cells,
        }
        // eslint-disable-next-line no-plusplus
        index++
        indexCell = 1
        dataRows.push(cell)
      }
      rows.push(headerRow)
      dataRows.forEach(row => {
        rows.push(row)
      })
      // rows.push(dataRows);
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('DanhSachSinhVienNhanHocBong.xlsx')
    },
    getScholarShipFundsName(id) {
      return this.scholarshipFunds.find(item => item.id === id)?.name || ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
